
<template>
  <b-card-code title="Reseller Information Table">
    <div class="custom-search">
      <!-- advance search input -->
      <validation-observer ref="simpleRulesSearch">
        <b-form>
          <b-row>

            <b-col md="6">
              <b-form-group>
                <label for="from_date">Full Name</label>
                <b-form-input v-model="name" placeholder="Enter Full Name" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="from_date">Company Name</label>
                <b-form-input v-model="company_name" placeholder="Enter Company Name" />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <label for="from_date">Country Code</label>

                <select class="form-control" name="countryCallingCode">
                  <option value="880">+880(Bangladesh)</option>
                  <option value="93">+93(Afghanistan)</option>
                  <option value="355">+355(Albania)</option>
                  <option value="213">+213(Algeria)</option>
                  <!-- Add more options for other countries -->
                  <option value="1">+1(United States)</option>
                  <option value="44">+44(United Kingdom)</option>
                  <!-- Add more options for other countries -->
                  <option value="260">+260(Zambia)</option>
                  <option value="263">+263(Zimbabwe)</option>
                </select>

              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="from_date">Mobile Number</label>
                <b-form-input v-model="mobile" placeholder="Enter Mobile Number" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="from_date">E-mail</label>
                <b-form-input v-model="email" placeholder="Enter E-mail" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="from_date">Password</label>
                <b-form-input v-model="password" placeholder="Enter Password" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="from_date">Confirm Password</label>
                <b-form-input v-model="password_confirmation" placeholder="Re-Type Password" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label for=""> </label>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info"
                  @click.prevent="searchInvoiceSettlement">
                  Create Reseller
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>


    <!-- table -->

  </b-card-code>
</template>
  
  <script>

  import { avatarText } from '@core/utils/filter'
  import { onUnmounted } from '@vue/composition-api'
  import useInvoicesList from '@/views/isp/invoice/invoice-list/useInvoiceList'
  import $ from "jquery";
  import invoiceStoreModule from '@/views/isp/invoice/invoiceStoreModule'
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import {
    BTable, BDropdown, BDropdownItem, BCard, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BLink, BTooltip, BMedia, BBadge, BButton, VBToggle, BSidebar, BForm, BFormTextarea,
  } from "bootstrap-vue";
  import { ISP_LIST, SETTLEMENT_LIST } from "@core/services/api";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { VueGoodTable } from "vue-good-table";
  // import store from '@/store/index'
  import store from "@/store";
  import Ripple from "vue-ripple-directive";
  import flatPickr from "vue-flatpickr-component";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import useJwt from "@/auth/jwt/useJwt";
  import vSelect from "vue-select";
  import { required, email } from "@validations";
  import { checkRes } from "@core/services/helper";
  // Use toast
  import Mixin from "@core/services/mixin";

  export default {
    mixins: [Mixin],
    directives: {
      Ripple,
      "b-toggle": VBToggle,
    },
    components: {
      BCardCode,
      VueGoodTable,
      BAvatar,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BRow,
      BCol,
      BLink,
      BTooltip,
      BMedia,
      BBadge,
      BButton,
      BSidebar,
      BForm,
      BFormTextarea,
      ValidationProvider,
      ValidationObserver,
      vSelect,
      flatPickr,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
    },
    data() {
      const statusOptions = [
        { value: 2, label: "Paid" },
        { value: 1, label: "Unpaid" },
        { value: 0, label: "Draft" },
      ];
      const resolveClientAvatarVariant = (status) => {
        if (status === "Partial Payment") return "primary";
        if (status === "Paid") return "danger";
        if (status === "Downloaded") return "secondary";
        if (status === "Draft") return "warning";
        if (status === "Sent") return "info";
        if (status === "Past Due") return "success";
        return "primary";
      };
      return {
        statusOptions,
        toggle: false,
        isShow: false,
        pageLength: 10,
        dir: false,
        required,
        columns: [
          { field: "receivable_amount", label: "SL" },
          { field: "isp", label: "USER", sortable: true },
          { field: "receivable_amount", label: "AMMOUNT",formatter: (val) => `${val} BDT`, },
          { field: "isp", label: "Company Name"},
          { field: "receivable_amount", label: "Total User" },
          { field: "receivable_amount", label: "Total Bill" },
          { field: "receivable_amount", label: "Paid" },
          { field: "receivable_amount", label: "Unpaid"},
        ],
        rows: [],
        invoice_ids: [],
        isp_list: [],
        isp_id:JSON.parse(localStorage.getItem('userData')),
        searchTerm: "",
        from_date: "",
        to_date: "",
        bank_trans_id: null,
        remakr: null,
        status: "",
        updateStatus: "",
        UpdateBankTransTd: null,
        settlementId: "",
      };
    },
    computed: {
      direction() {
        if (store.state.appConfig.isRTL) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dir = true;
          return this.dir;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false;
        return this.dir;
      },
    },
    created() {
      this.listSettlementData();
    },
    methods: {
      listSettlementData() {
        this.$http
          .get(process.env.VUE_APP_BASEURL + SETTLEMENT_LIST, {
            params: {
              isp_id: this.isp_id.id,
            },
          })
          .then((res) => {
            this.rows = res.data.data.settlementList;
          });
      },
      closeSideBar() {
        this.settlementId = null;
      }, 
      searchInvoiceSettlement() {
        this.$refs.simpleRulesSearch.validate().then((success) => {
          if (success) {
            this.$http
              .get(process.env.VUE_APP_BASEURL + SETTLEMENT_LIST, {
                params: {
                  status: this.status,
                  isp_id: this.isp_id.id,
                  bank_trans_id: this.bank_trans_id,
                  from_date: this.from_date,
                  to_date: this.to_date,
                },
              })
              .then((res) => {
                this.rows = res.data.data.settlementList;
              });
          }
        });
      },
      idpListData() {
        this.$http
          .get(process.env.VUE_APP_BASEURL + ISP_LIST)
          .then((res) => {
            this.isp_list = res.data.data;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              userData.value = undefined;
            }
          });
      },
      validationForm() {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            useJwt
              .updateSettlement({
                settlementId: this.settlementId ?? null,
                status: this.updateStatus ?? null,
                bank_trans_id: this.UpdateBankTransTd ?? null,
                remark: this.remakr ?? null,
              })
              .then((response) => {
                this.listSettlementData();
                if (checkRes(response.status) && response.data.data) {
                  this.isShow = false;
                  this.$router
                    .push({ name: "admin-settlement-list" })
                    .catch((success) => {
                      this.toastMessage("success", "Invoice Payment", response);
                    });
                } else {
                  this.$router
                    .push({ name: "admin-settlement-list" })
                    .then(() => {
                      this.toastMessage("warning", "Invoice Payment", response);
                    })
                    .catch((error) => {
                      this.toastMessage("danger", "Invoice Payment", response);
                    });
                }
              });
          }
        });
      },
    },
    mounted() {
      this.idpListData();
    },
  };
  </script>
  <style lang="scss" >
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  